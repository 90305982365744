import firebase from "firebase/app";
import "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyBuKELsgKiW2x6DAO-_6gjS0zzujVFBMH0",
  authDomain: "okapp-78f8d.firebaseapp.com",
  databaseURL: "https://okapp-78f8d.firebaseio.com",
  projectId: "okapp-78f8d",
  storageBucket: "okapp-78f8d.appspot.com",
  messagingSenderId: "821123838545",
  appId: "1:821123838545:web:6c85231b2c0ff238473e70",
  measurementId: "G-8CLTZ5L6C8"
};

firebase.initializeApp(config);

const firebaseDB = firebase.database();
const firebaseProperties = firebaseDB.ref("properties");
const firebaseContact = firebaseDB.ref("contact");

export { firebase, firebaseProperties, firebaseContact, firebaseDB };
